var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project_check_list"},[_c('el-card',[_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-input',{attrs:{"size":"medium","placeholder":"请输入联系人"},model:{value:(_vm.search.keyword),callback:function ($$v) {_vm.$set(_vm.search, "keyword", $$v)},expression:"search.keyword"}})],1),_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-select',{attrs:{"size":"medium","placeholder":"类型"},model:{value:(_vm.search.type),callback:function ($$v) {_vm.$set(_vm.search, "type", $$v)},expression:"search.type"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('nav',{staticClass:"input",staticStyle:{"width":"150px"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$refs.table.reload()}}},[_vm._v(_vm._s("搜索"))])],1)]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('z-table',{ref:"table",attrs:{"fullscreenLoading":""},on:{"query":_vm.queryList},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"typeName","label":"认证类型"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"联系人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.userName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"desc","label":"职位信息"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"view-city"},[(row.company)?_c('span',[_vm._v(_vm._s(row.company))]):_vm._e(),(row.job)?_c('span',[_vm._v(_vm._s(row.job))]):_vm._e(),(row.jobLevel)?_c('span',[_vm._v(_vm._s(row.jobLevel))]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"desc","label":"详情信息"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 1)?_c('div',[_c('p',[_vm._v("身份类型："+_vm._s(row.investorType))]),_c('p',[_vm._v("关注领域："+_vm._s(row.investField.toString()))]),_c('p',[_vm._v("偏好轮次："+_vm._s(row.investTurn.toString()))]),_c('p',[_vm._v("偏好地区："+_vm._s(row.investArea.toString()))])]):_vm._e(),(row.type == 2)?_c('div',[_c('div',[_vm._v(" 所在地区： "),_c('div',{staticClass:"view-city",staticStyle:{"display":"inline-block"}},[(row.province)?_c('span',[_vm._v(_vm._s(row.province))]):_vm._e(),(row.city)?_c('span',[_vm._v(_vm._s(row.city))]):_vm._e()])]),_c('p',[_vm._v("公司简介："+_vm._s(row.companyDesc))])]):_vm._e(),(row.type == 3)?_c('div',[_c('p',[_vm._v("机构类型："+_vm._s(row.agencyType))]),_c('p',[_vm._v("机构名称："+_vm._s(row.agencyName))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"businessCard","label":"名片"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p-image',{attrs:{"src":row.businessCard}})]}}])}),_c('el-table-column',{attrs:{"prop":"rights","label":"权益"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.rights),function(item,index){return _c('div',[_vm._v(_vm._s(item.name)+"*"+_vm._s(item.number))])})}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.Ftag(row.status))?_c('el-tag',{attrs:{"size":"small","type":row.status == 1 ? '' : row.status == 2 ? 'danger' : 'info'}},[_vm._v(" "+_vm._s(_vm.Ftag(row.status))+" ")]):_vm._e(),(row.status == 2)?_c('div',[_vm._v(" "+_vm._s(row.failReason)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"提交时间"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 2)?_c('el-button',{attrs:{"type":"text","icon":"el-icon-s-cooperation","size":"mini"},on:{"click":function($event){return _vm.giftBenefits(row)}}},[_vm._v("赠送权益")]):_vm._e(),_c('el-button',{staticStyle:{"color":"red"},attrs:{"slot":"reference","type":"text","icon":"el-icon-s-check","size":"mini"},on:{"click":function($event){return _vm.auditNotThrough(row)}},slot:"reference"},[_vm._v("审核不通过")]),_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.onDelete(row)}}},[_vm._v("删除")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.btn_if.indexOf('finance_view_contact') != -1),expression:"$store.state.btn_if.indexOf('finance_view_contact') != -1"}],staticClass:"green",attrs:{"type":"text","icon":"el-icon-camera","size":"mini"},on:{"click":function($event){return _vm.$refs.link.getFinance(row.userId)}}},[_vm._v("查看联系方式")])]}}])})],1)],1)],1),_c('Slink',{ref:"link",attrs:{"isView":""}}),_c('giftBenefits',{ref:"giftBenefits",on:{"getlist":_vm.getlist}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }